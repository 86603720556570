import ListView from 'view/listView';

class FeedListView extends ListView {
  constructor() {
    super();
    this.name = 'feed';
    this.className = 'FeedListView';
  }
  tagClassForClickLog(){
    return 'all';
  }
}
export default FeedListView;