import View from 'view/baseView';

class ItemView extends View{
  constructor() {
    super();
    this.className = 'ItemView';
  }
  _linkHref(itm){
    return itm.link;
  }
  _makeImgTag(itm){
    return itm.img ? '<div class="p-top__post--img"><img src="' + itm.img + '"></div>' : "";
  }
  _classForClickLog(){
    return this.className.replace(/ItemView/,'').toLowerCase();
  }
  _makeClassForTabClickLog(option){
    return option.tagClassForClickLog ? `tab-${option.tagClassForClickLog}-${this._classForClickLog()}-item` : `tab-${this._classForClickLog()}-item`;
  }
  _resolveTalent(talentId, option){
    return option.data.talent.find(t=>t.id === talentId);
  }

  render(itm, option){
    let html = '<div class="p-top__post--item">';
    html += `<a href="${this._linkHref(itm)}" class="p-top__post--link ${this._makeClassForTabClickLog(option)} total-${this._classForClickLog()}" target="_blank" data-kz-track="outbound">`;
    html += this._makeImgTag(itm);

    html += '<div class="p-top__post--detail"><div class="p-top__post--detail__inner">';
    html += '<h3 class="p-top__post--detail__ttl">' + itm.title + '</h3>';
    if(itm.price != null)
      html += '<p class="p-top__post--detail__price">' + itm.price + '</p>';
    if(itm.date != null)
      html += '<p class="p-top__post--detail__date">' + itm.date + '</p>';
    if(itm.talentIds != null){
      var cateView = itm.talentIds.map(x => this._resolveTalent(x, option)).filter(talent => talent && talent.name).map(talent => '<li>' + talent.name + '</li>').join('');
      html += `<ul class="p-top__post--detail__tag"><li class="${itm.type}">${itm.typeName}</li>${cateView}</ul>`;
    }
    html += '</div></div>';
    html += '</a>';
    html += '</div>';
    return $(html);
  }
}

export default ItemView;