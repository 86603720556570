// アイテム系
class Theater {
  static getTheaterLogo(place){
    const theaters = [
      {place:"よしもと漫才劇場(大阪府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/532/a1a9649311ad833e2c53ae3bdd3ad2737b4944de.png"},
      {place:"なんばグランド花月(大阪府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/533/2186b405091b69b32faf7c9ba82256f5f465bf25.png"},
      {place:"ルミネtheよしもと(東京都)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/534/2fc6568faf372dd844d10a8dccfe6aa3bf1adf97.png"},
      {place:"ヨシモト∞ホール(東京都)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/535/8f6758f480edd7bc31e2bc692f487aecdd72b9c9.png"},
      {place:"大宮ラクーンよしもと劇場(埼玉県)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/536/e17c61460699ab7fc72d0a6172e7ee5aee7ab7f0.png"},
      {place:"よしもと祇園花月(京都府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/537/52e2c4ca9c68b56b78f94e2111b6be421985a9bb.png"},
      {place:"沼津ラクーンよしもと劇場(静岡県)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/538/51f2f481265c5c4928b4c5cb49b127081f717791.png"},
      {place:"よしもと幕張イオンモール劇場(千葉県)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/539/03eb2105f2007c2a7c391a98b39c138a04719a85.png"},
      {place:"ヨシモト∞ドーム　ステージⅡ(東京都)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/540/532f71447516019b663d424a71e17c466fd4fe5a.png"},
      {place:"ヨシモト∞ドーム　ステージⅠ(東京都)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/540/532f71447516019b663d424a71e17c466fd4fe5a.png"},
      {place:"よしもと天神ビブレホール(福岡県)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/541/59420009b0ab35e771cef0bfb9bd44311fb39468.png"},
      {place:"よしもと沖縄花月(沖縄県)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/542/b57250d025478070d523d0700c3bfde64a6970c0.png"},
      {place:"神保町花月(東京都)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/543/08c9e0d201ca2754acf338bcbf7b2a388f433693.png"},
      {place:"YES THEATER(大阪府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/544/6fd70024c29f168baa12feba673c3078b7439900.png"},
      {place:"道頓堀ZAZA Box(大阪府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/545/b51b22cc8f98df60d5fd1e122becd0988ceec1a3.png"},
      {place:"道頓堀ZAZA HOUSE(大阪府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/545/b51b22cc8f98df60d5fd1e122becd0988ceec1a3.png"},
      {place:"道頓堀ZAZA POCKET’S (大阪府)", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/545/b51b22cc8f98df60d5fd1e122becd0988ceec1a3.png"},
      {place:"よしもと西梅田劇場", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/546/1f5c8e1ddc673db974f1b516e7401e2c900ba41b.png"},
      {place:"ポストよしもと", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/547/0d191b3103df61c401ab3d52c28dc8cb59c35f21.png"},
      {place:"朝日劇場", img:"https://cdn.kaizenplatform.net/v2/attachments/000/345/548/920ba3f69d2383726e7bdcec9863f6f8ee14a0f2.png"},
    ];
    const theater = theaters.find(v=>v.place === place) || {};
    return theater.img;
  }
}

export default Theater;