import View from 'view/baseView';

class TopView extends View {
  constructor() {
    super();
    this.className = 'TopView';
  }

  setCurrentView(name){
    $(() => {
      $('.is-active').removeClass('is-active');
      $('.is-show').removeClass('is-show');
      $(`.${name}`).addClass('is-active');
      $(`.p-top__post--list.${name}`).addClass('is-show');
    });
  }

  onTabChanged(callback){
    $(() => {
      $('.tabmenu li').click(function(){
        const name = String($(this).attr('kzTabId'));
        callback(name);
      });
    });
  }

  onWindowScroll(callback){
    $(window).on('scroll', callback);
  }

  initSmoothScroll() {
    $(() => {
      $('a[href^="#"]').not('.no-scroll, a[href="#"]').on('click', (e) => {
        var scrlID = $(e.target).attr('href');
        $('html, body').stop(0.5, 0).animate({
          scrollTop: scrlID === 'body' ? 0 : $(scrlID).offset().top
        }, 300, 'linear');
        return false;
      });
    });
  }
}

export default TopView;