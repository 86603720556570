// 一覧系
import request from "superagent";
import Goods from "../item/goods";
import News from "../item/news";
import Ticket from "../item/ticket";

const itemClass = {
  goods: Goods,
  news: News,
  ticket: Ticket,
}
class List {
  constructor() {
    if (new.target === List) {
      throw new TypeError("Cannot construct Abstract instances directly");
    }
    this.collection = '';
    this.query = {
      hitsPerPage: 20,
    }
    this.prevApiData = null;
    this.className = 'List';
  }

  setConf(conf){
    this.conf = conf;
    this.isDev = conf.isDev || false
  }

  apiUrl(){
    const apiendPoint = this.isDev ? 'https://asia-northeast1-kaizen-cse-dev.cloudfunctions.net/' : 'https://asia-northeast1-yoshimoto-feed-prod.cloudfunctions.net/'
    return apiendPoint + this.collection;
  }

  hasNext(){
    if (this.prevApiData !== null){
      return this.prevApiData.left > 0
    }else{
      return;
    }
  }

  canStartLoading(){
    let ret = this.hasNext();
    if (ret === undefined)
      ret = true;
    return ret;
  }

  getTalent(talentId){
    return this.prevApiData.talent.find(v=>v.id === talentId);
  }

  getTotalCount(){
    if (this.prevApiData){
      return this.prevApiData.totalCount;
    }
  }

  _convApiDataToItems(data){
    const items = [];
    data.body.hits.forEach((e)=>{
      const elementName = e.cursor.split('-')[0];
      const cls = itemClass[elementName];
      items.push(new cls({data: e}));
    });
    this.prevApiData = data.body;
    return Object.assign(data.body, {items: items});
  }

  getList(retry=0){
    if (this.prevApiData !== null){
      this.query.startAfter = this.prevApiData.hits[this.prevApiData.count-1].cursor;
      this.query.indexVer = this.prevApiData.indexVer;
    }
    return request
      .get(this.apiUrl())
      .timeout({
        response: 40000,  // Wait 20 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .query(this.query)
      .set('accept', 'json')
      .then(data=>{
        return this._convApiDataToItems(data);
      })
      .catch((error)=>{
        // feedListはindexVerエラー時にレスポンスを返さないので、statusを見ずに、エラー時は１回だけリトライする
        if(retry === 0){
          this.prevApiData = null;
          this.query.startAfter = null;
          this.query.indexVer = null;
          return this.getList(1);
        }else{
          return Promise.reject(error);
        }
      });
  }
}

export default List;