import List from './baseList'

class TicketList extends List {
  constructor() {
    super();
    this.collection = 'ticketSearch';
    this.className = 'TicketList';
  }
}

export default TicketList;