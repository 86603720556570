import Item from './baseItem'
class Goods extends Item{
  constructor(params={}) {
    super(params);
    this.typeName = 'グッズ';
    this.className = 'Goods';
  }
  get price(){
    return this.data.price;
  }
}

export default Goods;