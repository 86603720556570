import List from './baseList'

class FollowList extends List {
  constructor() {
    super();
    this.collection = 'feedSearch';
    this.className = 'FollowList';
  }
  setConf(conf){
    super.setConf(conf);
    this.query.talentIds = (conf.talentIds || []).join(',');
  }
  hasNext(){
    if (this.hasFollowTalents()){
      return super.hasNext();
    }else{
      return false;
    }
  }
  hasFollowTalents(){
    return this.conf.talentIds.length > 0
  }
  followTalents(){
    return this.prevApiData.talent
  }
}

export default FollowList;