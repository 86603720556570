class BaseController {
  constructor(conf){
    this.kzs = conf.kzs;
    this.console = this.kzs.console || console
    this.conf = conf;
    this.className = 'BaseController';
  }
  
  getApiConf(){
    return this.kzsReady()
      .then((kzs)=>{
        const kzStg = parseInt(kzs.cookies.get('_kz_stg')) || 0;
        const conf = {
          kzStg: kzStg,
          isDev: kzStg === 1
        }
        return conf
      });
  }

  /*
    kzsのbucketsの判断を待って、その値を返す。
  */
  buex(timeout=5000){
    return this.kzsReady(timeout)
      .then((kzs)=>{
        return kzs.current.buckets.excluded
      });
  }
  
  _getKzsReady(){
    return (this.kzs.current.executedActions && this.kzs.current.executedActions.length !== 0);
  }

  kzsReady(timeout=5000){
    return new Promise((resolve, reject)=>{
      const tid = setInterval(()=>{
        if (!this._getKzsReady()) return;
        clearInterval(tid);
        clearTimeout(timer);
        resolve(this.kzs);
      }, 100);
      const timer = setTimeout(()=>{
        clearInterval(tid);
        reject(new Error('timeout wait for kzs executedActions'));
      }, timeout);
    });
  }

  getFollowTalentIds(timeout=5000){
    return this.kzsReady(timeout).then((kzs)=>{
      const kzsUserData = kzs.current.userData['talentFollow'] || [];
      const talentIds =  kzsUserData.map(e=>e.id);
      return talentIds;
    });
  }

  run(){
    // サブクラスで要実装
  }

}

export default BaseController;
