import BaseController from 'controller/baseController';
import TopView from 'view/topView';
import FollowListController from 'controller/followListController';
import FeedListController from 'controller/feedListController';
import NewsListController from 'controller/newsListController';
import TicketListController from 'controller/ticketListController';

// トッップページ
class TopController extends BaseController {
  constructor(conf){
    super(conf);
    this.currentControllerName;
    this.view = new TopView();
    this.className = 'TopController';
  }

  run(){
    const view = this.view;

    view.initSmoothScroll();
    view.onWindowScroll(()=>{
      this.onWindowScroll()
    });
    view.onTabChanged((name)=>{
      this.onTabChanged(name)
    });

    this.setCurrentController('follow');

    this.followController = new FollowListController(this.conf);
    this.feedController = new FeedListController(this.conf);
    this.newsController = new NewsListController(this.conf);
    this.ticketController = new TicketListController(this.conf);
    
    return Promise.all([
      this.followController.run(),
      this.feedController.run(),
      this.newsController.run(),
      this.ticketController.run()
    ]);
  }

  setCurrentController(name){
    if (name !== this.currentControllerName){
      this.currentControllerName = name;
      this.view.setCurrentView(name);
    }
  }

  /*
    windowのスクロールが発生したときに、選択状態にあるコントローラーのstartLoadingIfTriggeredを呼ぶ
    これによって、新しいItemがAPIのレスポンスとして追加される
  */
  onWindowScroll(){
    const currentController = this[`${this.currentControllerName}Controller`];
    currentController.startLoadingIfTriggered();
  }

  onTabChanged(name){
    this.setCurrentController(name);
  }

}

export default TopController;