import ItemView from 'view/baseItemView';

class TicketItemView extends ItemView {
  constructor() {
    super();
    this.className = 'TicketItemView';
  }
  _linkHref(itm){
    return itm.kgidLink;
  }
  _makeImgTag(itm){
    return itm.img ? super._makeImgTag(itm) : '<div class="p-top__post--img"><p>' + itm.place + '</p></div>'
  }
}

export default TicketItemView;