import ListController from 'controller/listController';
import TicketListView from 'view/ticketListView';
import TicketList from 'model/list/ticketList';

// チケット
class TicketListController extends ListController {
  constructor(conf){
    super(conf);
    this.view = new TicketListView();
    this.model = new TicketList();
    this.className = 'TicketListController';
  }
}

export default TicketListController;