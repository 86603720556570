import View from 'view/baseView';
import GoodsItemView from 'view/goodsItemView';
import NewsItemView from 'view/newsItemView';
import TicketItemView from 'view/ticketItemView';

const itemViewClass = {
  goods: new GoodsItemView(),
  news: new NewsItemView(),
  ticket: new TicketItemView(),
}

// 一覧系
class ListView extends View {
  constructor() {
    super();
    this.name = 'list';
    this.className = 'ListView';
  }

  get selector(){
    return `.p-top__post--list.${this.name}`;
  }

  prepare(){
    let html = '';
    html += '<div class="p-top__post--list-inner"><div class="p-top__post--list-message" style="display:none"/></div>';
    html += '<div class="loading" style="display: none;"><img src="https://cdn.kaizenplatform.net/v2/attachments/000/345/505/75f6ae78589ed97adb21ae3c3941be0bae0d52ce.gif"></div>';
    $(this.selector).append($(html));
  }

  messageBox(){
    return $(`${this.selector} .p-top__post--list-inner div.p-top__post--list-message`);
  }

  // eslint-disable-next-line no-unused-vars
  updateMessage(model) {
    this.messageBox().hide();
  }

  tagClassForClickLog(){
    return null;
  }

  addListItems(data) {
    const listBox = $(`${this.selector} .p-top__post--list-inner`);
    const items = data.items;
    $(() => {
      if (data.offset === 0){
        listBox.find('.p-top__post--item').remove();
      }
      items.forEach((itm) => {
        const v = itemViewClass[itm.type];
        const $itm = v.render(itm, {tagClassForClickLog: this.tagClassForClickLog(), data: data});
        listBox.append($itm);
      });
    })
  }

  showLoading(duration=400){
    $(() => {
      if (duration === 0){
        $(`${this.selector} .loading`).show();
      }else{
        $(`${this.selector} .loading`).fadeIn(duration);
      }
    })
  }

  stopLoading(duration=400){
    $(() => {
      if (duration === 0){
        $(`${this.selector} .loading`).hide();
      }else{
        $(`${this.selector} .loading`).fadeOut(duration);
      }
    })
  }

  isTriggerLoading(){
    var $lastItem = $(`${this.selector} .p-top__post--item`).last();
    var tp = $lastItem.offset().top;
    var btp = tp - $(window).height();
    return $(window).scrollTop() > btp;
  }
}

export default ListView;