import List from './baseList'

class FeedList extends List {
  constructor() {
    super();
    this.collection = 'feedSearch';
    this.className = 'FeedList';
  }
}

export default FeedList;