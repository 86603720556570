import ListView from 'view/listView';

class FollowListView extends ListView {
  constructor() {
    super();
    this.name = 'follow';
    this.className = 'FollowListView';
  }
  tagClassForClickLog(){
    return 'follow';
  }
  updateMessage(m) {
    super.updateMessage(m);
    if(!m.hasFollowTalents()){
      $(() => {
        this.messageBox().show();
        this.messageBox().html('<p class="non_follow">現在フォロー中のタレントがおりません。<br>画面下部の「<a href="https://profile.yoshimoto.co.jp/talent/list/?keywords=&from_where1=0&age_from=&age_to=" target="_blank" class="follow_text_link">タレントをフォローする</a>」ボタンからお気に入りのタレントをフォローしてください。</p>');
      });
    }else if(m.getTotalCount() === 0){
      $(() => {
        this.messageBox().show();
        const talents = m.followTalents().map(talent=>{return `<a href="${talent.link}">${talent.name}</a>`});
        this.messageBox().html(`<p class="pick_follow">現在、フォロー中タレント（${talents.join(',')}）のニュース・チケット・グッズ情報はごさいません。<br>画面下部の「<a href="https://profile.yoshimoto.co.jp/talent/list/?keywords=&from_where1=0&age_from=&age_to=" target="_blank" class="follow_pick_text_link">タレントをフォローする</a>」ボタンから、他にも気になるタレントを探してフォローしてください。</p>`);
      });
    }
  }
}
export default FollowListView;