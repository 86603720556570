import ListController from 'controller/listController';
import NewsListView from 'view/newsListView';
import NewsList from 'model/list/newsList';

// ニュース
class NewsListController extends ListController {
  constructor(conf){
    super(conf);
    this.view = new NewsListView();
    this.model = new NewsList();
    this.className = 'NewsListController';
  }
}

export default NewsListController;