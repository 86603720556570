import ListController from 'controller/listController';
import FeedListView from 'view/feedListView';
import FeedList from 'model/list/feedList';

// すべて
class FeedListController extends ListController {
  constructor(conf){
    super(conf);
    this.view = new FeedListView();
    this.model = new FeedList();
    this.className = 'FeedListController';
  }
}

export default FeedListController;