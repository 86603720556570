import List from './baseList'

class NewsList extends List {
  constructor() {
    super();
    this.collection = 'newsSearch';
    this.className = 'NewsList';
  }
}

export default NewsList;