class View {
  constructor() {
    this.className = 'View';
  }
  render(model, options = {}){
    // サブクラスで実装してください
    if (model || options){
      return;
    }
  }
}

export default View;