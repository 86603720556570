import ItemView from 'view/baseItemView';

class GoodsItemView extends ItemView {
  constructor() {
    super();
    this.className = 'GoodsItemView';
  }
  _classForClickLog(){
    return 'good';
  }
}

export default GoodsItemView;