import C from 'controller/topController';

kzs.console.log('kzTopController', 'v20200116-1');
window.kzTopController = new C({kzs: kzs});
window.kzTopController.run();

/*var model = 'model/model';
var view = 'view/view';
var controller = 'controller/controller';
$.getScript(model, view, controller , function(){
  var p = new kzTopController().run();
});*/



/*import C from 'controller/hello';

kzs.console.log('kzHelloController', 'v20191119-1');
window.kzHelloController = C;*/



/*import Model from 'model/model';
import View from 'view/view';
import Controller from 'controller/controller';

new Model().run();
new View().run();
new Controller().run();

kzs.console.log('kzTopController', 'v20191119-1');*/



/*import Model from 'model/model';
import View from 'view/view';
import Controller from 'controller/controller';

const Promise = kzs.Promise;
const $ = kzs.$;

export default class {
  run() {
    var m = { model: new Model() };
    var v = { view: new View() };
    var c = { controller: new Controller() };
    return new Model().run(m)
      .then(()=> new View().run(v))
      .then(()=> new Controller().run(c))
  }
}*/