import ListController from 'controller/listController';
import FollowListView from 'view/followListView';
import FollowList from 'model/list/followList';

// フォロー
class FollowListController extends ListController {
  constructor(conf){
    super(conf);
    this.view = new FollowListView();
    this.model = new FollowList();
    this.className = 'FollowListController';
  }
  getConf(){
    return this.getFollowTalentIds()
      .then((talentIds)=>{
        return super.getConf().then((conf)=>{
          conf.talentIds = talentIds;
          return conf;
        })
      })
  }
}

export default FollowListController;