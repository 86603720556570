class Item{
  constructor(params={}) {
    this.data = params.data;
    this.className = 'Item';
  }
  get title(){
    return this.data.title;
  }
  get img(){
    let img = this.data.img
    if(!img) {
      img = 'https://cdn.kaizenplatform.net/v2/attachments/000/345/715/0867f2aef5900452c43a3f488220730eace1352f.png';
    }
    return img;
  }
  get link(){
    return this.data.link;
  }
  get talentIds(){
    return this.data.talentIds;
  }
  get date(){
    return this.data.date;
  }
  get cursor(){
    return this.data.cursor;
  }
  get elementName(){
    return this.data.elementName || this.data.cursor.split('-')[0];
  }
  get type(){
    return this.className.toLowerCase();
  }
}

export default Item;