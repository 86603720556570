import Item from './baseItem'
import Theater from './theater'

class Ticket extends Item{
  constructor(params={}) {
    super(params);
    this.typeName = 'チケット';
    this.className = 'Ticket';
  }
  get img(){
    return Theater.getTheaterLogo(this.data.place);
  }
  get kgidLink(){
    return this.data.kgidLink;
  }
  get place(){
    return this.data.place;
  }
}

export default Ticket;