import BaseController from 'controller/baseController';

// 一覧系
class ListController extends BaseController {
  constructor(conf){
    super(conf);
    this.view; // in subclass set view instance
    this.model; // in subclass set model instance
    this.addListItemsExecuting = false;
    this.className = 'ListController';
  }
  getConf(){
    return this.getApiConf();
  }
  run(){
    return this.getConf()
      .then((conf)=>{
        this.model.setConf(conf);
      })
      .then(()=>{
        this.view.prepare();
        this.view.render(this.model);
      })
      .then(()=>{
        return this.addListItems();
      })
      .then((data)=>{
        this.kzs.console.log('afterAddListItems');
        this.afterAddListItems();
        return data;
      })
  }
  canStartLoading(){
    return this.model.canStartLoading() && !this.addListItemsExecuting;
  }

  addListItems(){
    if (this.canStartLoading()){
      this.addListItemsExecuting = true;
      this.view.showLoading();
      //this.kzs.console.log('start addListItems');
      return this.model.getList()
      .then((data)=>{
        this.view.stopLoading(0);
        this.view.addListItems(data);
        //this.kzs.console.log('end addListItems');
        this.addListItemsExecuting = false;
        return {name: this.className, canStartLoading: true, state: data.state};
      })
      .catch((error)=>{
        this.view.stopLoading(0);
        this.addListItemsExecuting = false;
        return {name: this.className, canStartLoading: true, error: error};
      })
    }else{
      this.view.stopLoading(0);
      //this.kzs.console.log('addListItems is not executed');
      return {name: this.className, canStartLoading: false, state: 'not run'};
    }
  }

  afterAddListItems(){
    this.view.updateMessage(this.model);
  }

  startLoadingIfTriggered(){
    if(this.canStartLoading() && this.view.isTriggerLoading()){
      this.kzs.console.log('startLoadingTriggered')
      this.addListItems();
    }
  }
}

export default ListController;